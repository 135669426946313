<template>
  <div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-login"
      no-close-on-backdrop
      centered
      ok-only
      size="lg"
      hide-footer="true"
      title="Add Slider"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              ref="image"
              label="Desktop Image"
              invalid-feedback="Desktop Image is required."
            >
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="myObj.desktopImage"
                      style="height: 150px; width: 150px; object-fit: cover"
                      @click="showImg(myObj.desktopImage)"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="false">
            <b-form-group
              ref="mobile"
              label="Mobile Image"
              invalid-feedback="Mobile Image is required."
            >
              <b-media no-body>
                <div v-if="otherLoading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.otherProfile.click()"
                      v-if="otherLoading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="otherLoading == 'loaded'">
                    <div>
                      <i
                        @click="deleteOther()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="myObj.mobileImage"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="otherProfile"
                    hidden
                    ref="otherProfile"
                    @change="selectOther()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="Author"
          invalid-feedback="Author is required."
          ref="author"
        >
          <b-form-input
            v-model.trim="myObj.saidBy"
            placeholder="Enter author name"
            @focusout="checkAuthor()"
          />
        </b-form-group>
        <b-form-group
          label="Designation"
          invalid-feedback="Designation is required."
          ref="designation"
        >
          <b-form-input
            v-model.trim="myObj.designation"
            placeholder="Enter author designation"
            @focusout="checkDesignation()"
          />
        </b-form-group>
        <b-form-group invalid-feedback="Quote is required." ref="quote">
          <div class="d-flex justify-content-between pb-50">
            <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
              >Quote</label
            >
            <div class="d-flex">
              <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                >Quote at right:</label
              >
              <b-form-checkbox
                v-model="myObj.textRight"
                switch
                class="mr-0 ml-1"
              />
            </div>
          </div>
          <b-form-textarea
            v-model.trim="myObj.quote"
            placeholder="Enter quote"
            rows="4"
          />
        </b-form-group>
      </b-form>
      <br />
      <div>
        <b-button
          class="float-right ml-5"
          variant="primary"
          small
          :disabled="logoloading == true || request || otherLoading == true"
          @click="AddFlavour()"
        >
          <b-spinner
            v-if="request"
            variant="light"
            small
            type="grow"
            label="Spinning"
          ></b-spinner>
          <span class="text-nowrap" v-else>Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Slider</span>
                </b-button>
              </div>
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields" striped>
          <!-- <template #cell(#)="data">
            {{ data.index + 1 }}
          </template> -->
          <template #cell(image)="data">
            <b-img
              :src="data.item.desktopImage"
              style="width: 100px; height: 100px; object-fit: cover"
              rounded
              alt="image"
            />
          </template>
          <template #cell(actions)="data">
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item.id)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete"
                @click="Delete(data.item.id)"
              >
                <feather-icon size="16" icon="Trash2Icon" />
              </b-button>
            </template>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import moment from "moment";
import { forEach } from "postcss-rtl/lib/affected-props";

export default {
  components: {
    //Multiselect,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormGroup,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    //BAvatar,
    BLink,
    BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    this.LoadData();
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      currentDate: moment().format("YYYY-MM-DD"),
      plus: "https://cdn.cloudious.net/file-1678189226453-406060527.png",
      fileProfile: "",
      logoloading: false,
      otherLoading: false,
      request: false,
      imgList: [],
      errors: {
        validity: false,
        code: false,
        discountPercent: false,
        limit: false,
        validity: false,
      },
      statusOptions: ["Active", "Inactive"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        { label: "image", key: "image" },
        { label: "Author", key: "saidBy" },
        { label: "designation", key: "designation" },
        "actions",
      ],
      items: [],
      searchQuery: "",
      subCatItems: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      myObj: {
        id: 0,
        saidBy: "",
        designation: "",
        quote: "",
        desktopImage: "",
        mobileImage: "",
        textRight: false,
      },
      detailObj: {},
    };
  },
  methods: {
    checkImage() {
      var elem = this.$refs["image"];
      if (this.myObj.desktopImage == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAuthor() {
      var elem = this.$refs["author"];
      if (this.myObj.saidBy == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDesignation() {
      var elem = this.$refs["designation"];
      if (this.myObj.designation == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkQuote() {
      var elem = this.$refs["quote"];
      if (this.myObj.quote == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkOther() {
      var elem = this.$refs["mobile"];
      if (this.myObj.mobileImage == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          saidBy: "",
          designation: "",
          quote: "",
          desktopImage: "",
          mobileImage: "",
          textRight: false,
        };
        this.logoloading = false;
        this.otherLoading = false;

        this.$bvModal.show("modal-login");
      } else {
        var axios = require("axios");
        var config = {
          method: "get",
          url: "https://aafia.cloudious.net/api/Slider/" + id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        axios(config)
          .then((response) => {
            this.myObj = response.data.data;
            if (this.myObj.desktopImage) {
              this.logoloading = "loaded";
            } else {
              this.logoloading = false;
            }
            if (this.myObj.mobileImage) {
              this.otherLoading = "loaded";
            } else {
              this.otherLoading = false;
            }
            this.$bvModal.show("modal-login");
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://aafia.cloudious.net/api/Slider/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Slider has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];

      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.myObj.desktopImage = e.data.myresp[0].path;
            this.logoloading = "loaded";
            this.checkImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    deleteLogo() {
      this.myObj.desktopImage = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectOther() {
      let doc = this.$refs.otherProfile.files[0];

      var axios = require("axios");
      if (doc !== "") {
        this.otherLoading = true;
        let formData = new FormData();
        formData.append("file", doc);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.myObj.mobileImage = e.data.myresp[0].path;
            this.otherLoading = "loaded";

            this.checkOther();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteOther() {
      this.myObj.mobileImage = "";
      this.otherLoading = false;
      this.$refs.otherProfile.value = null;
    },
    showImg(url) {
      window.open(url, "_blank");
    },
    AddFlavour() {
      this.checkImage();
      this.checkAuthor();
      this.checkDesignation();
      this.checkQuote();
      // this.checkOther();
      if (
        this.checkImage() == false ||
        this.checkAuthor() == false ||
        this.checkDesignation() == false ||
        this.checkQuote() == false
        // this.checkOther() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        this.request = true;
        // console.log(this.myObj);

        if (this.myObj.id == 0) {
          var axios = require("axios");

          var config = {
            method: "post",
            url: "https://aafia.cloudious.net/api/Slider",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                // console.log(response.data);
                this.request = false;

                this.$bvModal.hide("modal-login");
                this.LoadData();
                Swal.fire("Success!", "Slider Added Successfully.", "success");
              }
            })
            .catch(function(error) {
              console.log(error);
              this.request = false;
            });
        } else {
          //Edit
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://aafia.cloudious.net/api/Slider/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                this.request = false;

                this.$bvModal.hide("modal-login");
                // console.log(response.data);
                this.LoadData();

                this.$bvToast.toast("Slider updated.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
                this.request = false;
              }
            })
            .catch(function(error) {
              console.log(error);
              this.request = false;
            });
        }
      }
    },

    LoadData() {
      var axios = require("axios");

      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Slider",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          this.items = response.data.data;
          // console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
